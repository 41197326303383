<template>
	<section>

		<div class="banner">
			<div class="section">
				<div class="section-content banner-con">
					<div class="top-title">招募渠道合作伙伴</div>
					<div class="top-text">
						<p>代理区域保护，广告、培训、运营、资源全方位赋能</p>
						<p>助力伙伴持续高速成长</p>
					</div>
				</div>
			</div>
		</div>
		<!-- 代理商权益 -->
		<div class="section">
			<div class="section-content">
				<!-- 产品服务 -->
				<div class="part1">
					<div class="part">
						<div class="title-module">
							<div class="main-title">代理商权益</div>
							<div class="sub-title">
								<p>为企业搭建一站式营销中心，可轻松</p>
								<p>对接多个营销终端。</p>
							</div>
						</div>

						<div class="part1-con">
							<div class="p1-con-item">
								<i class="p1-con-icon iconfont icon-guanliliucheng"></i>
								<p class="p1-con-title">系统管理</p>
								<div class="p1-con-con">
									<p>可视化、实时化的数据平台<p/>
									<p>帮助代理商实现高效管理</p>
								</div>	
							</div>
							<div class="p1-con-item">
								<i class="p1-con-icon iconfont icon-icon_huabanfuben"></i>
								<p class="p1-con-title">市场支持</p>
								<div class="p1-con-con">
									<p>享受总部提供的多重市场推广资源</p>
									<p>助力业务全方位突破</p>
								</div>	
							</div>
							<div class="p1-con-item">
								<i class="p1-con-icon iconfont icon-peixun"></i>
								<p class="p1-con-title">培训系统</p>
								<div class="p1-con-con">
									<p>为代理商提供产品、售前、招商</p>
									<p>多维度的专业培训</p>
								</div>	
							</div>
							<div class="p1-con-item">
								<i class="p1-con-icon iconfont icon-gerenzhongxin-zaixiankefu-"></i>
								<p class="p1-con-title">客服系统</p>
								<div class="p1-con-con">
									<p>多平台客服入口、数据信息云服务中心</p>
									<p>服务数据分析，智能化服务支持</p>
								</div>	
							</div>
							<div class="p1-con-item">
								<i class="p1-con-icon iconfont icon-danwei" style="font-size: 34px;"></i>
								<p class="p1-con-title">物料支持</p>
								<div class="p1-con-con">
									<p>提供品牌设计、物料更新、内容素材</p>
									<p>助力业务全方位突破</p>
								</div>	
							</div>
							<div class="p1-con-item">
								<i class="p1-con-icon iconfont icon-icon_huabanfuben1" style="font-size: 42px;"></i>
								<p class="p1-con-title">内容支持</p>
								<div class="p1-con-con">
									<p>提供案例包装、话术库、行业方案</p>
									<p>助力伙伴成功</p>
								</div>	
							</div>
							<div class="p1-con-item">
								<i class="p1-con-icon iconfont icon-tongyi-31"></i>
								<p class="p1-con-title">品牌支持</p>
								<div class="p1-con-con">
									<p>通过品牌广告、媒体曝光、社会化传播</p>
									<p>打造面向全国的品牌生态支持</p>
								</div>	
							</div>
							<div class="p1-con-item">
								<i class="p1-con-icon iconfont icon-fuwu1"></i>
								<p class="p1-con-title">服务支持</p>
								<div class="p1-con-con">
									<p>实时响应，随时处理服务商及企业客户</p>
									<p>遇到的各种问题</p>
								</div>	
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="part2">
			<div class="section">
				<div class="section-content part1-con part2-con">
					<div class="p1-con-item">
						<i class="p1-con-icon iconfont icon-quyu"></i>
						<p class="p1-con-title">区域保护</p>
						<div class="p1-con-con">
							<p>代理区域保护，总部客户资源支持<p/>
						</div>	
					</div>
					<div class="p1-con-item">
						<i class="p1-con-icon iconfont icon-renmai"></i>
						<p class="p1-con-title">人脉保护</p>
						<div class="p1-con-con">
							<p>代理商辖区推广的新客户获得保护</p>
						</div>	
					</div>
					<div class="p1-con-item">
						<i class="p1-con-icon iconfont icon-leijishouyi"></i>
						<p class="p1-con-title">长期收益</p>
						<div class="p1-con-con">
							<p>享受成交收益，持续续费收益</p>
						</div>	
					</div>
					<div class="p1-con-item">
						<i class="p1-con-icon iconfont icon-shouyiqingkuang"></i>
						<p class="p1-con-title">收益升级</p>
						<div class="p1-con-con">
							<p>代理升级享受季度分红收益</p>
						</div>	
					</div>
				</div>
			</div>
		</div>
		<div class="section">
			<div class="section-content">
				<div class="part1">
					<div class="condition">
						<div class="title-module">
							<div class="main-title">加盟条件</div>
							<div class="sub-title">
								<p>为企业搭建一站式营销中心，可轻松</p>
								<p>对接多个营销终端。</p>
							</div>
						</div>
						<div class="border-box">
							<div class="condition-item">
								<div class="condition-title">申请条件</div>
								<p class="condition-oper">企业和个人均可申请</p>
							</div>
							<div class="condition-item">
								<div class="condition-title">品牌认同</div>
								<p class="condition-oper">认同聚码品牌使命、愿景、价值观</p>
							</div>
							<div class="condition-item">
								<div class="condition-title">合作意愿</div>
								<p class="condition-oper">愿意长期从事互联网事业，与总部充分协作</p>
							</div>
							<div class="condition-item">
								<div class="condition-title">服务能力</div>
								<p class="condition-oper">To B 领域软件销售经验，维护客情关系</p>
							</div>
							<div class="condition-item">
								<div class="condition-title">业务能力</div>
								<p class="condition-oper">有稳定的客户资源和持续拓展市场的能力</p>
							</div>
						</div>
						<div class="progress">
							<div class="progress-list">
								<div class="progress-item">
									<i class="iconfont icon-faqishenqing"></i>
									<el-progress :width="90" :stroke-width="1" :show-text="false" type="circle" :percentage="20"></el-progress>
								</div>
								<p>提交申请</p>
							</div>
							<div class="pro-icon">
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
							</div>
							<div class="progress-list">
								<div class="progress-item">
									<i class="iconfont icon-2Nxiaodihezuo"></i>
									<el-progress :width="90" :stroke-width="1" :show-text="false" type="circle" :percentage="40"></el-progress>
								</div>
								<p>合作洽谈</p>
							</div>
							<div class="pro-icon">
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
							</div>
							<div class="progress-list">
								<div class="progress-item">
									<i class="iconfont icon-daiqianxieyi"></i>
									<el-progress :width="90" :stroke-width="1" :show-text="false" type="circle" :percentage="60"></el-progress>
								</div>
								<p>签订协议</p>
							</div>
							<div class="pro-icon">
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
							</div>
							<div class="progress-list">
								<div class="progress-item">
									<i class="iconfont icon-peixunguanli"></i>
									<el-progress :width="90" :stroke-width="1" :show-text="false" type="circle" :percentage="80"></el-progress>
								</div>
								<p>业务培训</p>
							</div>
							<div class="pro-icon">
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
								<i class="iconfont icon-xiangyou"></i>
							</div>
							<div class="progress-list">
								<div class="progress-item">
									<i class="iconfont icon-shuju1"></i>
									<el-progress :width="90" :stroke-width="1" :show-text="false" type="circle" :percentage="100"></el-progress>
								</div>
								<p>开展业务</p>
							</div>
						</div>
						<div class="play-button">申请加盟</div>
						<p class="play-in"><span>*</span> 当前暂不开放线上加盟申请，敬请谅解</p>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script type="text/javascript">
	export default {
    data(){
        return {
        	hha: 'wrewe'
        }
    },
	mounted(){
		console.log('this is partners')
	},
	methods: {

	},
  
  // router
}

</script>
<style type="text/css" scoped>
	.banner{
		width: 100%;
		height: 300px;
		background: url(https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/partner01.png);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: bottom;
		background-color: rgba(255,255,255,0.1);
    	background-blend-mode: multiply;
		color: #333333;
		overflow: hidden;
	}
	.banner-con{
		padding-left: 120px;
	}
	.top-title{
		font-size: 24px;
		line-height: 24px;
		font-weight: bolder;
		margin-top: 80px;
	}
	.top-text{
		font-size: 15px;
		font-weight: 300;
		padding-top: 6px;
	}

	.part{
    	width: 100%;
    	background-color: #fff;
    	border-radius: 30px;
    	overflow: hidden;
    	margin-top: 60px;
    }

    .part1-con{
    	width: 100%;
    	display: flex;
    	flex-wrap: wrap;
    	/*margin-left: 20px;*/
    	padding: 0 40px 64px 40px;

    }
    .p1-con-item{
    	width: 23.5%;
    	margin-right: 2%;
    	display: flex;
    	align-items: center;
    	justify-content: flex-start;
    	flex-direction: column;
    	margin-top: 60px;
    }
    .p1-con-item:nth-of-type(4n){
    	margin-right: 0;
    }
    .p1-con-icon{
    	line-height: 40px;
        font-size: 40px;
        font-weight: 300;
    	color: #6f6868;
    }
    .part1 .p1-con-icon{
    	-webkit-text-stroke:0.3px white;
	    -moz-text-stroke:0.5px white;
	    -o-text-stroke:0.5px white;
	    text-stroke:0.5px white;
    }
    .p1-con-title{
    	font-size: 16px;
    	line-height: 16px;
    	font-weight: bold;
    	color: #666666;
    	padding-top: 14px;
    }
    .p1-con-con{
    	font-size: 12px;
    	color: #848282;
    	padding-top: 14px;
    	text-align: center;
    }
    .part1{
    	width: 100%;
    	padding-left: 40px;
    	padding-right: 40px;
    }

    .part2{
    	width: 100%;
    	height: 263px;
    	background: url(https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/partner02.png);
    	background-repeat: no-repeat;
    	background-size: cover;
    	background-position: center;
    	margin-top: 40px;
    }
    .part2-con *{
    	color: #fff!important;
    }

    .condition{
    	background-color: #fff;
    	border-radius: 30px;
    	overflow: hidden;
    	margin-top: 60px;
    }

    .border-box{
        position: relative;
        width:580px;
        border: 3px solid #00a1ff;
        margin: 60px auto;
        padding: 30px 65px 40px 115px;
    }
    .border-box::before {
        position: absolute;
        top: -3px;
        bottom: -3px;
        left: 15px;
        width: calc(100% - 30px);
        content: "";
        border-top: 3px solid #fff;
        border-bottom: 3px solid #fff;
        z-index: 0;
    }
    .border-box::after {
        position: absolute;
        top: 15px;
        right: -3px;
        left: -3px;
        height: calc(100% - 30px);
        content: "";
        border-right: 3px solid #fff;
        border-left: 3px solid #fff;
        z-index: 0;
    }
    .condition-item{
    	width: 100%;
    	display: flex;
    	align-items: center;
    	color: #333333;
    	padding-bottom: 8px;
    }
    .condition-title{
    	width: 92px;
    	flex-shrink: 0;
    	font-size: 16px;
    	font-weight: bolder;
    }
    .condition-oper{
    	font-size: 15px;
    }
    .progress{
    	width: 100%;
    	display: flex;
    	align-items: center;
    	justify-content: center;
    	margin-top: 120px;
    }
    .progress-list{
    	display: flex;
    	align-items: center;
    	justify-content: center;
    	flex-direction: column;
    }
    .progress-list p{
    	font-size: 14px;
    	line-height: 14px;
    	color: #333333;
    	margin-top: 10px;
    }
    .progress-item{
    	/*width: 90px;
    	height: 90px;
    	border: 1px solid red;
    	border-radius: 100%;*/
    	display: flex;
    	align-items: center;
    	justify-content: center;
    	position: relative;
    	margin: 0 12px;
    }
    .progress-item i{
    	font-size: 32px;
    	color: #00a1ff;
    	position: absolute;
    	top: 50%;
    	left: 50%;
    	transform: translateX(-50%) translateY(-50%);
    }
    .pro-icon{
    	display: flex;
    	align-items: center;
    }
    .pro-icon i{
    	display: block;
    	font-size: 12px;
    	color: #00a1ff;
    	margin-left: -6px;
    }
    .play-button{
    	width: 190px;
    	height: 36px;
    	background-color: #999;
    	display: flex;
    	align-items: center;
    	justify-content: center;
    	font-size: 14px;
    	color: #fff;
    	border-radius: 5px;
    	margin: 120px auto 0 auto;
    	cursor: pointer;
    }
    .play-button:hover{
    	opacity: 0.8;
    }
    .play-in{
    	font-size: 12px;
    	color: #333;
    	text-align: center;
    	margin-top: 10px;
    	margin-bottom: 80px;
    	display: flex;
    	align-items: center;
    	justify-content: center;
    }
</style>